@font-face {
  font-family: 'moglonglight';
  src: url('../font/moglong-light-webfont.eot');
  src: url('../font/moglong-light-webfont.eot?#iefix') format('embedded-opentype'),url('../font/moglong-light-webfont.woff') format('woff'),url('../font/moglong-light-webfont.ttf') format('truetype'),url('../font/moglong-light-webfont.svg#moglonglight') format('svg');
  font-weight: normal;
  font-style: normal;
  }

@font-face {
  font-family: 'moglongregular';
  src: url('../font/moglong-regular-webfont.eot');
  src: url('../font/moglong-regular-webfont.eot?#iefix') format('embedded-opentype'),url('../font/moglong-regular-webfont.woff') format('woff'),url('../font/moglong-regular-webfont.ttf') format('truetype'),url('../font/moglong-regular-webfont.svg#moglongregular') format('svg');
  font-weight: normal;
  font-style: normal;
  }

@font-face {
  font-family: 'moglongbook';
  src: url('../font/moglong-book-webfont.eot');
  src: url('../font/moglong-book-webfont.eot?#iefix') format('embedded-opentype'),url('../font/moglong-book-webfont.woff') format('woff'),url('../font/moglong-book-webfont.ttf') format('truetype'),url('../font/moglong-book-webfont.svg#moglongbook') format('svg');
  font-weight: normal;
  font-style: normal;
  }


.margin-welcome{
  margin-top: 200px;
  position: relative;
}

.margin-responsive{
  margin-top: 0;
}

@media (max-width: 992px){
  .margin-responsive{
    margin-top: 150px !important;
  }
}

.navbar-brand{
  /*width: none;*/
}

.navbar-toggler{
  margin-top: 30px;
}


.bg-faded {
  background-color: #fff;
  position: relative;
}

.navbar-light .navbar-nav .nav-link {
  color: #b1b1b1;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 30px;
}

@media (max-width: 1200px){
  .navbar-light .navbar-nav .nav-link {
    color: #b1b1b1;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 30px;
  }
}


.site-welcome{
  margin-top: 165px;
  background: rgb(230,228,226);
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,rgba(230,228,226,1) 0%, rgba(229,227,225,1) 25%, rgba(230,228,226,1) 50%, rgba(229,227,225,1) 75%, rgba(230,228,226,1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(230,228,226,1)), color-stop(25%,rgba(229,227,225,1)), color-stop(50%,rgba(230,228,226,1)), color-stop(75%,rgba(229,227,225,1)), color-stop(100%,rgba(230,228,226,1)));
  background: -webkit-linear-gradient(top,rgba(230,228,226,1) 0%,rgba(229,227,225,1) 25%,rgba(230,228,226,1) 50%,rgba(229,227,225,1) 75%,rgba(230,228,226,1) 100%);
  background: -o-linear-gradient(top,rgba(230,228,226,1) 0%,rgba(229,227,225,1) 25%,rgba(230,228,226,1) 50%,rgba(229,227,225,1) 75%,rgba(230,228,226,1) 100%);
  background: -ms-linear-gradient(top,rgba(230,228,226,1) 0%,rgba(229,227,225,1) 25%,rgba(230,228,226,1) 50%,rgba(229,227,225,1) 75%,rgba(230,228,226,1) 100%);
  background: linear-gradient(to bottom,rgba(230,228,226,1) 0%,rgba(229,227,225,1) 25%,rgba(230,228,226,1) 50%,rgba(229,227,225,1) 75%,rgba(230,228,226,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6e4e2', endColorstr='#e6e4e2',GradientType=0 );
}

@media (max-width: 320px){
  .navbar-brand{
    width: 50%;
  }
  .navbar-toggler{
    margin-top: 0;
  }
  .site-welcome{
    margin-top: 0;
  }
}


h2.welcome{
  color: #7D7D7D;
  font-size: 50px;
  margin: 50px 0 10px;
  font-family: 'moglongregular';
}

p.desc-welcome{
  margin: 20px 0 0;
  color: #7D7D7D;
  font-size: 30px;
  font-family: 'moglonglight';
}

.site-philo {
  padding-top: 100px;
  padding-bottom: 100px;
  color: #fff;
  background: rgb(84,124,168);
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…BoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-radial-gradient(center, ellipse cover,rgba(84,124,168,1) 0%, rgba(31,52,76,1) 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(84,124,168,1)), color-stop(100%,rgba(31,52,76,1)));
  background: -webkit-radial-gradient(center, ellipse cover,rgba(84,124,168,1) 0%,rgba(31,52,76,1) 100%);
  background: -o-radial-gradient(center, ellipse cover,rgba(84,124,168,1) 0%,rgba(31,52,76,1) 100%);
  background: -ms-radial-gradient(center, ellipse cover,rgba(84,124,168,1) 0%,rgba(31,52,76,1) 100%);
  background: radial-gradient(ellipse at center,rgba(84,124,168,1) 0%,rgba(31,52,76,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#547ca8', endColorstr='#1f344c',GradientType=1 );
}

h2.philo{
  font-size: 50px;
  margin: 5px 0 0;
  font-family: 'moglongregular';
}

p.philo{
  font-size: 17px;
  margin: 10px 0 0;
  font-family: 'moglonglight';
}

.founder{
  padding-top: 50px;
}


.site-gallery{
  color:#fff;
  padding-top: 100px;
  padding-bottom: 100px;
  background: url("../img/gallery.jpg") no-repeat scroll center bottom transparent;
}

h2.gallery{
  font-size: 50px;
  margin: 5px 0 0;
  font-family: 'moglongregular';
  padding-bottom: 15px;
}

#slides .item{
  margin: 3px;
}
#slides .item img{
  display: block;
  width: 100%;
  height: auto;
}

.customNavigation{
  text-align: center;
}
/*use styles below to disable ugly selection*/
.customNavigation a{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


.site-press{
  padding-top: 100px;
  padding-bottom: 100px;
  background: rgb(230,228,226);
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,rgba(230,228,226,1) 0%, rgba(229,227,225,1) 25%, rgba(230,228,226,1) 50%, rgba(229,227,225,1) 75%, rgba(230,228,226,1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(230,228,226,1)), color-stop(25%,rgba(229,227,225,1)), color-stop(50%,rgba(230,228,226,1)), color-stop(75%,rgba(229,227,225,1)), color-stop(100%,rgba(230,228,226,1)));
  background: -webkit-linear-gradient(top,rgba(230,228,226,1) 0%,rgba(229,227,225,1) 25%,rgba(230,228,226,1) 50%,rgba(229,227,225,1) 75%,rgba(230,228,226,1) 100%);
  background: -o-linear-gradient(top,rgba(230,228,226,1) 0%,rgba(229,227,225,1) 25%,rgba(230,228,226,1) 50%,rgba(229,227,225,1) 75%,rgba(230,228,226,1) 100%);
  background: -ms-linear-gradient(top,rgba(230,228,226,1) 0%,rgba(229,227,225,1) 25%,rgba(230,228,226,1) 50%,rgba(229,227,225,1) 75%,rgba(230,228,226,1) 100%);
  background: linear-gradient(to bottom,rgba(230,228,226,1) 0%,rgba(229,227,225,1) 25%,rgba(230,228,226,1) 50%,rgba(229,227,225,1) 75%,rgba(230,228,226,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6e4e2', endColorstr='#e6e4e2',GradientType=0 );
}

h2.press{
  color: #7D7D7D;
font-size: 50px;
margin: 5px 0 0;
font-family: 'moglongregular';
}

p.press{
  color: #7D7D7D;
font-size: 17px;
font-family: 'moglonglight';
}

.site-contact{
  min-height: 570px;
  background-image: url("../img/contact.png");
  background-size: contain;
  background-color: rgb(230,228,226);
  background-repeat: no-repeat;
  background-position: 100px;
}

@media (max-width: 992px){
  .site-contact{
    min-height: 570px;
    background-image: none;
    background-size: contain;
    background-color: rgb(230,228,226);
    background-repeat: no-repeat;
    background-position: 100px;
  }
}

img.img-contact{
  position: absolute;
  width: 130%;
  overflow: hidden;
}

@media (max-width: 768px){
  img.img-contact{
    margin-top: -70px;
    position: relative;
  }
}

h2.contact{
  color: #7D7D7D;
font-family: 'moglongregular';
font-size: 50px;
margin: 0;
}

.footer{
  background-color: #242424;
  color: #FFF;
  position: relative;
  font-size: 12px;
}

p.footer{
  margin-top: 20px;
}

.leakngakakbutton{
  border: none;
  background: transparent;
  color: white;
  margin: 0 20px;
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');